// import router from '@/router'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import axiosAuth from '@/utils/AxiosAuth'
import { useRuntimeStore } from '@/stores/runtimeStore'
import {
  findItem,
  createCustomer,
  findCustomer
} from '@/services/quickbooksServices'
import { storeToRefs } from 'pinia'
import { useJobStore } from '@/stores/jobStore'
import { useClientStore } from './clientStore'
import { useAdmin } from '@/stores/adminStore'
import { notify } from 'notiwind'

// import { useToggle } from '@/composables/index.js'

export const useQuickbooks = defineStore('quickbooksStore', () => {
  const qbExpenseAccountId = ref(null)
  const qbIncomeAccountId = ref(null)
  const qbAssetAccountId = ref(null)
  const qbImportItems = ref([])
  const connectedToQuickbooks = ref(false)
  const showQuickbooks = ref(false)
  const showQbAuthModal = ref(false)
  const quickbooksAccountList = ref([])
  const accountType = ref(null)
  const quickbooksStatus = ref(null)
  const quickbooksIncomeAccounts = ref([])
  const quickbooksExpenseAccounts = ref([])
  const quickbooksAssetAccounts = ref([])
  const taxRates = ref({ product: 0, service: 0 })
  const taxRateList = ref([])
  const selectedTaxRate = ref(null)
  const unsyncedItems = ref([])
  const unsyncedModal = ref(false)
  const invoiceIsSynced = ref(false)
  const quickbookRequestData = ref()
  const clientModal = ref(false)
  const saveError = ref(false)
  const saveSuccess = ref(false)
  const getQuickbooksAccount = async () => {
    const root = `${useRuntimeStore().$state.apiRoot}/billing`
    await axiosAuth
      .get(`${root}/quickbooks/accounts`)
      .then((res) => {
        quickbooksIncomeAccounts.value = res.data.income_accounts
        quickbooksExpenseAccounts.value = res.data.expense_accounts
        quickbooksAssetAccounts.value = res.data.asset_accounts
        // quickbooksAccountList.value = res.data
        // console.log("_________________getQuickbooksAccount__length__________________________", res.data);
      })
      .catch((err) => {
        console.log('getQuickbooksAccount', err)
      })
  }

  // const navigateToQBLogin = async () => {
  //     const { adminSettings } = storeToRefs(useAdmin())
  //   const { skippedQbSignIn } = storeToRefs(useAdmin())
  //
  //   // window.open(`${apiRoot.value}/billing/quickbooks/get_login_url/`)
  //     const root = `${useRuntimeStore().$state.apiRoot}/billing/quickbooks/get_login_url/`
  //     await axiosAuth
  //           .get(`${root}`)
  //           .then((res) => {
  //             adminSettings.value.quickbooks_token_active = true
  //             adminSettings.value.quickbooks_logged_in = true
  //             if (skippedQbSignIn.value) {
  //               clearQbSkip()
  //             }
  //           })
  //           .catch((err) => {
  //             console.log('getQuickbooksAccount', err)
  //           })
  // }

  const loginRedirect = async () => {
    const { adminSettings } = storeToRefs(useAdmin())
    const { getAdminSettings } = useAdmin()
    const root = `${useRuntimeStore().$state.apiRoot}/billing`
    console.log('login_redirect>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
    adminSettings.value.quickbooks_logged_in = true
    await axiosAuth
      .get(`${root}/quickbooks/login_redirect`, {params: quickbookRequestData.value})
      .then((res) => {

      })
      .catch((err) => {
        adminSettings.value.quickbooks_logged_in = false
        console.log('getQuickbooksAccount', err)
      })

    await getAdminSettings()
  }

  const signInToQuickbooks = async () => {
    quickbooksStatus.value.logged_in = true
  }

  const checkQuickbooksStatus = async () => {
    let result = quickbooksStatus.value.logged_in
    if (result) {
      await getQuickbooksAccount()
      connectedToQuickbooks.value = true
      showQuickbooks.value = true
    }
    return quickbooksStatus.value.logged_in
  }

  const signOutOfQuickbooks = async () => {
    const { adminSettings } = storeToRefs(useAdmin())
    connectedToQuickbooks.value = false
    const root = `${useRuntimeStore().$state.apiRoot}/billing`
    await axiosAuth
      .get(`${root}/quickbooks/logout`)
      .then(() => {
        adminSettings.value.quickbooks_logged_in = false
        // quickbooksStatus.value.logged_in = false
      })
      .catch((err) => {
        console.log('createQbProductError', err)
      })
  }

  const createQBCustomer = async (client) => {
    await createCustomer(client.id)
  }

  const createQbProduct = async (product) => {
    let result
    const root = `${useRuntimeStore().$state.apiRoot}/billing`
    let body = {
      qb_income_account_id: product.qb_income_account_id,
      qb_expense_account_id: product.qb_expense_account_id,
      qb_asset_account_id: product.qb_asset_account_id
    }

    await axiosAuth
      .post(`${root}/quickbooks/product/${product.id}`, body)
      .then((res) => {
        result = res.data
        let index = unsyncedItems.value.findIndex(
          (item) => item.id === product.id
        )
        if (index > -1) {
          unsyncedItems.value.splice(index, 1)
        }
      })
      .catch((err) => {
        console.log('createQbProductError', err)
      })
    return result
  }
  const createQbService = async (service) => {
    let result
    const root = `${useRuntimeStore().$state.apiRoot}/billing`
    let body = {
      qb_income_account_id: service.qb_income_account_id
    }
    await axiosAuth
      .post(`${root}/quickbooks/service/${service.id}`, body)
      .then((res) => {
        result = res.data
        let index = unsyncedItems.value.findIndex(
          (item) => item.id === service.id
        )
        if (index > -1) {
          unsyncedItems.value.splice(index, 1)
        }
      })
      .catch((err) => {
        console.log('createQbProductError', err)
      })
    return result
  }

  const itemLookup = async (itemName, partial, type1, type2) => {
    let res = await findItem(itemName, partial)
    // Need to limit returned matches to 'Inventory' for Products
    // and 'Service' for Services
    if (res.QueryResponse?.Item) {
      let filteredItems = res.QueryResponse.Item.filter(
        (item) => item.Type === type1 || item.Type === type2
      )
      qbImportItems.value = filteredItems
    }
  }
  const clientLookup = async (clientName, partial) => {
    let res = await findCustomer(clientName, partial)
    qbImportItems.value = res?.QueryResponse?.Customer
  }

  const createJobInvoice = async (projectId) => {
    const root = `${useRuntimeStore().$state.apiRoot}/billing`
    // const { getJobById } = useJobStore()
    const { saveClient } = useClientStore()

    await axiosAuth
      .post(`${root}/quickbooks/invoice/${projectId}`)
      .then(async () => {
        // await getJobById(projectId)

        notify(
          {
            group: 'success',
            title: 'Success',
            text: 'Invoice has been synced to Quickbooks'
          },
          2000
        )
        await saveClient(true)
        invoiceIsSynced.value = true
        clientModal.value = false
        unsyncedModal.value = false
      })
      .catch((err) => {
        let error = err.response.data
        invoiceIsSynced.value = false
        // let errorMessage = error.message
        notify(
          {
            group: 'error',
            title: 'Error',
            text: err.response.data.message
          },
          2000
        )

        let lineItems = error.detail['line items'].map((item) => {
          return item
        })
        unsyncedItems.value = lineItems
        unsyncedModal.value =  true
      })
  }

  // if QB is active, this creates a payment in Mantx and in QB with one call
  const createJobPayment = async (projectId, payment) => {
    const root = `${useRuntimeStore().$state.apiRoot}`
    const { selectedJob } = storeToRefs(useJobStore())
    await axiosAuth
      .post(`${root}/projects/${projectId}/payment`, payment)
      .then((res) => {
        let newPayment = res.data.payments.reverse()[0] 
        selectedJob.value.payments.push(newPayment)
        selectedJob.value.balance = res.data.balance
        saveSuccess.value = true
      })
      .catch((err) => {
        saveError.value = true
        console.log('createJobInvoiceError1', err)
      })
  }

  const createPaymentFailure = async (projectId, error) => {
    // If payment with Clearent fails, send the failure data to API
    const root = `${useRuntimeStore().$state.apiRoot}`
    let body = {
      payment_service_response: error
    }
    await axiosAuth
      .post(`${root}/projects/${projectId}/payment/failure`, body)
      .then((res) => {
        console.log('createPaymentFailure', res.data)
      })
      .catch((err) => {
        saveError.value = true
        console.log('createJobInvoiceError2', err)
      })
  }

  const updateJobPayment = async (projectId, payment) => {
    const root = `${useRuntimeStore().$state.apiRoot}`
    const { selectedJob } = storeToRefs(useJobStore())
    await axiosAuth
      .put(`${root}/projects/${projectId}/payment/${payment.id}`, payment)
      .then((res) => {
        selectedJob.value = res.data
      })
      .catch((err) => {
        saveError.value  =true
        console.log('createJobInvoiceError3', err)
      })
  }

  const setTaxRates = (value) => {
    // currently setting both tax rates to the same value
    // until we determine QB Online differing tax rates
    taxRates.value = {
      product: value,
      service: value
    }
  }

  const updateTaxRates = async (taxRateId) => {
    const root = `${useRuntimeStore().$state.apiRoot}`
    const { adminSettings } = storeToRefs(useAdmin())
    await axiosAuth
      .post(`${root}/billing/quickbooks/tax_rates/${taxRateId}`)
      .then((res) => {
        adminSettings.value.quickbooks_tax_code = res.data.quickbooks_tax_code
        adminSettings.value.product_tax_rate = res.data.product_tax_rate
      })
      .catch((err) => {
        console.log('setTaxRatesError', err)
      })
  }
  const getTaxRates = async () => {
    const root = `${useRuntimeStore().$state.apiRoot}`

    await axiosAuth
      .get(`${root}/billing/quickbooks/tax_rates`)
      .then((res) => {
        console.log('getTaxRates', res.data)
      })
      .catch((err) => {
        console.log('getTaxRatesError', err)
      })
  }

  const listTaxRates = async () => {
    const { adminSettings } = storeToRefs(useAdmin())
    const root = `${useRuntimeStore().$state.apiRoot}/billing`
    await axiosAuth
      .get(`${root}/quickbooks/list_tax_rates`)
      .then((res) => {
        taxRateList.value = res.data
        if(adminSettings.value.quickbooks_tax_code?.name == null){
          updateTaxRates(taxRateList.value[0].id)
        }
        adminSettings.value.quickbooks_logged_in = true
      })
      .catch((err) => {
        adminSettings.value.quickbooks_logged_in = false
        console.log('getTaxRatesError', err)
      })
  }

  const values = {
    quickbooksAccountList,
    connectedToQuickbooks,
    qbExpenseAccountId,
    qbIncomeAccountId,
    qbAssetAccountId,
    createQBCustomer,
    accountType,
    showQuickbooks,
    quickbooksStatus,
    quickbooksIncomeAccounts,
    quickbooksExpenseAccounts,
    quickbooksAssetAccounts,
    qbImportItems,
    taxRates,
    taxRateList,
    selectedTaxRate,
    unsyncedItems,
    unsyncedModal,
    invoiceIsSynced,
    showQbAuthModal,
    quickbookRequestData,
    clientModal,
    saveError,
    saveSuccess
  }

  const actions = {
    getQuickbooksAccount,
    signInToQuickbooks,
    signOutOfQuickbooks,
    createCustomer,
    createJobInvoice,
    createJobPayment,
    createPaymentFailure,
    updateJobPayment,
    itemLookup,
    clientLookup,
    createQbProduct,
    createQbService,
    setTaxRates,
    updateTaxRates,
    getTaxRates,
    listTaxRates,
    checkQuickbooksStatus,
    loginRedirect,
    // navigateToQBLogin
  }

  return {
    ...values,
    ...actions
  }
})
