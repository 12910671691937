<script setup>
import { storeToRefs } from 'pinia'
import { ref, computed, onBeforeMount } from 'vue'
import {useJobStore} from '@/stores/jobStore'
import EstimateFooter from '@/pages/apps/jobs/UI/EstimateFooter.vue'
import avatar2 from '@images/avatars/avatar-2.png'
import { useAttach } from '@/stores/attachmentStore'
import { printInvoice } from '@/services/quickbooksServices'


import bgLogin from '@/assets/images/login_background.jpg'
import CustomerPayment from './CustomerPayment.vue'
import { useTheme } from 'vuetify/lib/framework.mjs'
import { useCookie } from '@/@core/composable/useCookie'
import { cookieRef, namespaceConfig } from '@/@layouts/stores/config'
import { useStorage } from '@vueuse/core'



const { selectedJob, currentEstimate } = storeToRefs(useJobStore())
const {signEstimateToken ,getJobByAccessToken, } = useJobStore()
const {uploadEstimateSignatureToken } = useAttach()
const signPreview = ref(false);
const signPreviewIndex = ref(null);
const vuetifyTheme = useTheme()


definePage({
meta: {
        layout: 'blank',
        public: true,
      },
    })
    
onBeforeMount(async () =>{
if (typeof Intercom === 'function') {
      Intercom('shutdown');
    }
  await getJobByAccessToken(route.query.access_token)
  vuetifyTheme.global.name.value = selectedJob.value.customization_setting.theme
    vuetifyTheme.global.current.value.colors.primary = selectedJob.value.customization_setting.primary_color
    vuetifyTheme.global.current.value.colors['primary-darken-1'] =  selectedJob.value.customization_setting.primary_color
    cookieRef(`${ vuetifyTheme.name.value }ThemePrimaryColor`, null).value = selectedJob.value.customization_setting.primary_color
  cookieRef(`${ vuetifyTheme.name.value }ThemePrimaryDarkenColor`, null).value = selectedJob.value.customization_setting.primary_color
  useStorage(namespaceConfig('initial-loader-color'), null).value = selectedJob.value.customization_setting.primary_color
    console.log(useCookie('MANTX-color-scheme').value)
})

const route = useRoute('customer-portal')
function calculateTotalPrice(price, units, margin) {
  if (!units) return formatToDollarValue(0)
  const markupDecimal = margin / 100
  let totalPrice = price * units * (1 + markupDecimal)
  totalPrice = formatToDollarValue(totalPrice)
 return totalPrice
}

const props = defineProps({
  mail: {
    type: Boolean,
    required: true,
  },
  type:{
    type: String,
    required: true
  }
})

const previewType = route.query.type


const handleSignature = async (file) => {
  await uploadEstimateSignatureToken(file,route.query.access_token)
  await signEstimateToken(selectedJob.value.id,currentEstimate.value.id,route.query.access_token) 
}
const computedTotal = computed(() => {
  let total = 0
  if (currentEstimate.value) {
    currentEstimate.value?.product_line_items.forEach((product) => {
      if (!isNaN(product.base_price) && !isNaN(product.units) && !isNaN(product.markup)) {
        let markupDecimal = product.markup / 100
        total += product.base_price * product.units * (1 + markupDecimal)
      }
    })
    currentEstimate.value?.service_line_items.forEach((service) => {
      if (!isNaN(service.hourly_rate) && !isNaN(service.hours) && !isNaN(service.markup)) {
        let markupDecimal = service.markup / 100
        total += service.hourly_rate * service.hours * (1 + markupDecimal)
      }
    })
    // currentEstimate.value.discount_line_items.forEach((discount) => {
    //     total -= discount.discount_amount
    // })

  }
   let tax =    currentEstimate.value.total_tax_amount
   if (tax){
  total +=  tax
  }
  return isNaN(total) ? 0 : total
})

const printPdf = () =>{
    printInvoice(selectedJob.value.id,currentEstimate.value.id, route.query.access_token)
}
const signerUrl = ref({ url: null });
   const openSignPreview = async(index, url) => {
    signPreviewIndex.value = index;
    signerUrl.value.url = url;
  signPreview.value = true;
  };

 const formatUrl =(url) => {
    if (!url) return '#';
    // Check if the URL already starts with http:// or https://
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  }

</script>
<template class="v-theme--dark" >
  <div  v-if="selectedJob"
    class="client-portal bg-cover elevation-4"
    :style="{
      background: `linear-gradient(rgba(255, 255, 255, 0.05), rgba(0, 0, 0, 0.9)), url(${selectedJob?.customization_setting?.background_image || bgLogin})`,
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed', /* Ensures the background image doesn't scroll */
      backgroundPosition: 'center',
      height: '100vh' /* Ensures the div covers the full viewport height */
    }"
  >
   
  <v-container class="bg-white h-screen py-5 px-5" >
      <VCard class="pa-4 rounded-4 elevation overflow-y-auto h-100" >
      <div class="d-flex flex-wrap justify-space-between">
  <!-- Left Section: Company Logo or Name -->
  <div class="d-flex align-center ">
    <VImg
  v-if="selectedJob?.company_logo"
  :src="selectedJob?.company_logo"
  alt="Company Logo"
  width="300"
/>
    <h1 
      v-else 
      class="text-xl font-bold uppercase ml-4 mt-2"
    >
      {{ selectedJob?.company_name.toUpperCase() }}
    </h1>
  </div>
<div class="d-flex align-center ">
  <div class="text-end">
    <div class="mb-1">{{ selectedJob?.company_address }}</div>
    <div class="mb-1">{{ selectedJob?.company_email }}</div>
    <div class="mb-1">
      <a  :href="formatUrl(selectedJob?.company_website)">{{ selectedJob?.company_website }}</a>
    </div>
    <div class="mb-1">{{ selectedJob?.company_phone }}</div>
  </div>
</div>
</div>




<div class="mt-3">
          <div v-if="selectedJob" class="invoice-header-preview print-row bg-var-theme-background rounded py-6 px-md-6 px-4 mb-6 d-flex flex-column">
            <div class="d-flex flex-wrap justify-space-between gap-3 align-center pb-4 mb-4 border-b-sm">
               <div class="d-flex flex-column gap-1">
                  <h6 class="mb-0 text-h5 text-dark">Estimate </h6>
                  <p class="mb-0 text-h5 text-dark"> #{{ selectedJob.job_number }}</p>
               </div>
               <div class="d-flex flex-column gap-2">
                    <!-- 👉 Issue Date -->
                  <h6 class="text-body-1 font-weight-regular d-flex justify-space-between gap-2">
                    <span>Date Issued: </span>
                    <span>{{ new Date(selectedJob.start_date).toLocaleDateString('en-US') }}</span>
                  </h6>
               </div>
            </div>
            <div class="d-flex flex-wrap gap-5">
               <div class="flex-1-1 d-flex flex-column gap-3">
                 <h6 class="text-h6 font-weight-medium text-uppercase">Status</h6>
                   <v-chip-group class="py-0">
                      <v-chip  class="text-uppercase v-theme--light text-warning v-chip--density-default" size="small" label>{{selectedJob.status}}</v-chip>
                      <v-chip  class="text-uppercase v-theme--light text-warning v-chip--density-default" size="small" label>{{selectedJob.secondary_status}}</v-chip>
                   </v-chip-group>
               </div>
               <div class="flex-1-1 d-flex flex-column gap-3">
                 <h6 class="text-h6 font-weight-medium text-uppercase">Client</h6>
                 <div>
                   <h6 class="text-h6 font-weight-medium">
                   {{ selectedJob.client.name }}
                   </h6>
                   <h6 class="text-body-2 font-weight-regular text-capitalize">
                     {{  selectedJob.client.type}}
                   </h6>
                 </div>

               </div>
               <div class="flex-1-1 d-flex flex-column gap-3">
                 <h6 class="text-h6 font-weight-medium text-uppercase">Service Address</h6>
                 <div>
                   <h6 class="text-body-1 font-weight-regular">
                     {{ selectedJob.service_address.address.line_1 }}
                   </h6>
                   <h6 class="text-body-1 font-weight-regular">
                     {{ selectedJob.service_address.address.line_2 }}
                   </h6>
                   <h6 class="text-body-1 font-weight-regular">
                     {{ `${selectedJob.service_address.address.city},  ${selectedJob.service_address.address.state}` }}
                   </h6>
                 </div>
               </div>
               <div class="flex-1-1 d-flex flex-column gap-3">
                 <h6 class="text-h6 font-weight-medium text-uppercase">Sales Rep</h6>
                 <div class="d-flex gap-2 align-center">
              <VAvatar :image="selectedJob.arborist.profile_image ? selectedJob.arborist.profile_image : avatar2" />

                   <div class="d-flex flex-column">
                    <h6 class="text-h6 font-weight-medium text-capitalize">

                     {{ `${selectedJob.arborist.name.first}  ${selectedJob.arborist.name.last}` }}
                    </h6>
                     <h6 class="text-body-1 font-weight-medium text-capitalize">
                     {{ (selectedJob.arborist.extension
              ? '(' + selectedJob.arborist.extension + ') '
              : '') + formatPhone(selectedJob.arborist.phone)  }}

                    </h6>

                    </div>

                 </div>
               </div>
            </div>
          </div>
  <VTable  class="border text-high-emphasis overflow-hidden mb-6">
    <thead style="background-color: primary;"   v-if="currentEstimate?.product_line_items?.length " >
               
              <tr>
                <th class="text-no-wrap" colspan="12">ESTIMATE</th>
              </tr>
              <tr class="text-primary"> <td  style="color: green" class="text-h5">Products</td></tr>
              <tr>
                <th scope="col">
                  NAME
                </th>
                <th scope="col">
                  DESCRIPTION
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  PRICE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in currentEstimate?.product_line_items"
                :key="item.name"
              >
              <td class="text-no-wrap">
                  <div class="mb-2">
                    {{ item.name }}
                  </div>
                </td>
                <td>
                  <VRow class="flex-nowrap py-2 justify-center">
                  <VCol>
                    <p class="mt-2" v-html="formatDisplayText(item.estimate_description)"></p>
                  </VCol>
                </VRow>
                </td>
                <td class="text-center">
                  {{calculateTotalPrice(item.base_price, item.units, item.markup)}}
                </td>
                </tr>
            </tbody>

          <thead  v-if="currentEstimate?.service_line_items && currentEstimate?.service_line_items.length > 0">
              <tr> <td class="text-h5" style="color: green">Services</td></tr>
              <tr>
                <th scope="col">
                  NAME
                </th>
                <th scope="col">
                  DESCRIPTION
                </th>


                <th
                  scope="col"
                  class="text-center"
                >
                 Price
                </th>
              </tr>
            </thead>
            <tbody  >
              <tr class="p-5"
                v-for="item in currentEstimate?.service_line_items"
                :key="item.name"
              >
                <td class="text-no-wrap">
                  <div class="mb-2">
                    {{ item.name }}
                  </div>

                </td>
                <td>
                  <VRow class="flex-nowrap py-2 justify-center">
                  <VCol>
                    <p class="mt-2" v-html="formatDisplayText(item.estimate_description)"></p>
                  </VCol>
                </VRow>
                </td>
               <td class="text-center">
                 {{
          calculateTotalPrice(item.hourly_rate, item.hours, item.markup)
        }}
                </td>
                </tr>


            </tbody>

    <thead style="background-color: primary;"  v-if="currentEstimate && currentEstimate.agreements?.length">
    <tr> <td class="text-h5" style="color: green" >Agreements</td></tr>
    </thead>
<template v-if="currentEstimate" v-for="(agreement) in currentEstimate.agreements" >

    <thead >
    <tr > <td class="text-h5 border-t-md">{{agreement.name}}</td>
      <td class="border-t-md"></td>
      <td class="border-t-md"></td>
    </tr>

    <tr >
      <th scope="col" style="color: green !important; " colspan="7"  >
       VISITS
      </th>
    </tr>
    </thead>

  <template  v-for="(visit,index) in agreement.visits">

    <thead   >
    <tr class="text-h6 " style="  background-color: rgba(var(--v-theme-secondary), var(--v-activated-opacity));
" >
      <td  scope="col" colspan="7">
        Visit {{index+1}}
      </td>
    </tr>
    </thead>
    <thead>
    <tr >
      <th scope="col">
        NAME
      </th>
      <th scope="col">
        DESCRIPTION
      </th>

      <th
          scope="col"
          class="text-center"
      >
        Price
      </th>
    </tr>
    </thead>

    <tbody>
    <tr
        v-for="item in visit.line_items.filter((item) => item.selected)"
        :key="item.id"
    >
      <td class="text-no-wrap">
        <div class="mb-2">
          {{ item.service ? item.service.name : item.product.name}}
        </div>

      </td>
      <td >
<!--        <VCol cols="10" >-->
          <p class="mt-2" v-html="formatDisplayText(item.description)"></p>
<!--        </VCol>-->
      </td>
      <td class="text-center">
        {{ item.service ?  calculateTotalPrice(item.service?.hourly_rate, item.units, item?.markup) : calculateTotalPrice(item.product?.base_price, item.units, item.markup)}}
      </td>
    </tr>
    </tbody>
  </template>
</template>
<tbody>
<tr v-if="selectedJob && currentEstimate">
  <td colspan="3">
    <div class="d-flex align-center">
      <div class="flex-1-1">
        <div class="d-flex justify-content-between gap-3 mt-3 mb-3 align-center">
          <div v-if="currentEstimate?.signer?.url && currentEstimate.status != 'draft'" class="align-center">
                            <section   data-test="estimate-signed-box" class="d-flex align-center gap-2 px-2 py-2 rounded dark:text-teal-200 text-teal-500 bg-teal-500/[0.25] border border-teal-500/[0.15] rounded-md border-opacity-10 justify-evenly">
                              <div class="d-flex align-center">
                                <svg width="24" height="24" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg" stroke-width="1"><rect y="0.5" width="35" height="35" rx="17.5" fill="#5aad56"></rect><path d="M15.9141 24.8906C15.75 25.1094 15.5312 25.1641 15.3125 25.1641C15.0391 25.1641 14.8203 25.1094 14.7109 24.8906L9.02344 19.2031C8.80469 19.0391 8.75 18.8203 8.75 18.6016C8.75 18.3828 8.80469 18.1641 9.02344 17.9453L10.2266 16.7422C10.3906 16.5781 10.6094 16.4688 10.8281 16.4688C11.0469 16.4688 11.2656 16.5781 11.4844 16.7422L15.3125 20.5703L23.5156 12.3672C23.6797 12.2031 23.8984 12.0938 24.1172 12.0938C24.3359 12.0938 24.5547 12.2031 24.7734 12.3672L25.9766 13.5703C26.1406 13.7891 26.25 14.0078 26.25 14.2266C26.25 14.4453 26.1406 14.6641 25.9766 14.8281L15.9141 24.8906Z" fill="white"></path></svg>
                              </div>
                              <p class="text-lg font-bold mb-0">Signed</p>
                              <p class="text-sm font-bold mb-0">{{formatUTCDate(currentEstimate.signed_date)}}</p>
                              <v-btn v-show="currentEstimate?.signer?.url" variant="tonal" @click="openSignPreview(index, currentEstimate.signer.url)" >View</v-btn>
                            </section>

                            <template>
                              <SignPreview v-if="signPreviewIndex === index && signPreview" v-model:isDialogVisible="signPreview" :signer="signerUrl.url"/>
                            </template>

                          </div>
            <EstimateFooter
                v-if="!mail"
                :estimate="currentEstimate"
                :selectedJobId="selectedJob.id"
                :preview="true"
                @sign-estimate="handleSignature"
            />
            <v-btn @click="printPdf" variant="outlined">Print PDF</v-btn>

        </div>
      </div>
      <div>
        <div class="d-flex justify-end font-weight-bold text-h5">
          <div class="d-flex flex-column gap-1 text-end justify-content-end text-h6">

            <div  v-if="(props.type == 'Estimate' || previewType == 'Estimate')" >TAX TOTAL: {{currentEstimate.total_tax_amount ? formatToDollarValue(currentEstimate.total_tax_amount) :  formatToDollarValue(0)}}</div>
            <div> TOTAL: {{ formatToDollarValue(computedTotal)  }}</div>

            <div v-if="props.type != 'Estimate' && mail">BALANCE: {{formatToDollarValue(selectedJob.balance) }}</div>
          </div>
        </div>
      </div>
    </div>
  </td>
</tr>
</tbody>
</VTable>
<CustomerPayment v-if="selectedJob.status == 'billing'"/>
</div>
  <v-dialog v-model="signPreview" persistent max-width="600px">
      <v-card>
        <v-card-title class="d-flex align-items-center blue darken-1 white--text">
          <span class="headline">Signature</span>
          <i class="ri-close-circle-line" style="position: absolute; top: 0; right: 0; z-index: 10; cursor: pointer;" @click="signPreview=false"></i>
        </v-card-title>
          <div class="mx-4 mt-2 mb-4 bg-white rounded">
      <img class="w-100 h-full" :src="signer ?? currentEstimate.signer.url" alt="" />
          </div>
       <v-card-actions class="blue darken-1">
          <v-btn  @click="signPreview=false" class="white--text ms-auto">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</VCard>
</v-container>
</div>
</template>



<style lang="scss">
.invoice-preview-table {
  --v-table-header-color: var(--v-theme-surface);

  &.v-table .v-table__wrapper table thead tr th{
    border-block-end: 1px solid rgba(var(--v-border-color), var(--v-border-opacity)) !important;
  }
}

@media print {
  .v-theme--dark {
    --v-theme-surface: 255, 255, 255;
    --v-theme-on-surface: 94, 86, 105;
  }

  body {
    background: none !important;
  }

  .invoice-header-preview,
  .invoice-preview-wrapper {
    padding: 0 !important;
  }

  .product-buy-now {
    display: none;
  }

  .v-navigation-drawer,
  .layout-vertical-nav,
  .app-customizer-toggler,
  .layout-footer,
  .layout-navbar,
  .layout-navbar-and-nav-container {
    display: none;
  }

  .v-card {
    box-shadow: none !important;

    .print-row {
      flex-direction: row !important;
    }
  }

  .layout-content-wrapper {
    padding-inline-start: 0 !important;
  }

  .v-table__wrapper {
    overflow: hidden !important;
  }
}
</style>




